<!-- eslint-disable array-callback-return -->
<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-card>
        <div class="code-prin-block d-flex align-items-center justify-content-between">
          <div class="code-status-block d-flex align-items-center">
            <div class="code-block">
              <div class="label">
                MÃ PHIẾU NHẬP HÀNG
              </div>
              <div class="code">
                {{ `PN-${model.id}` }}
              </div>
            </div>
            <div
              v-if="model.status == 2"
              class="code-block"
            >
              <div class="label">
                NGÀY NHẬP HÀNG
              </div>
              <div class="code">
                {{ model.stock_date }}
              </div>
            </div>
            <div class="code-block">
              <div class="label">
                TRẠNG THÁI
              </div>
              <div class="code">
                <b-badge
                  class=""
                  :variant="mixStatus('receivingGood', model.status).color"
                >
                  {{ $t(mixStatus('receivingGood', model.status).name) }}
                </b-badge>
              </div>
            </div>
          </div>
          <div class="prin-save-block d-flex align-items-center">
            <b-button
              type="button"
              class="prin-block d-flex align-items-center"
              to="add"
            >
              <feather-icon
                icon="PrinterIcon"
                size="14"
              />
              <div class="name">
                In
              </div>
            </b-button>
            <b-button
              variant="outline-primary"
              type="button"
              class="ml-2"
              to="add"
            >
              {{ $t('Trả hàng') }}
            </b-button>

            <b-button
              v-if="model.status == 1"
              variant="primary"
              type="button"
              class="ml-2"
              @click="updateStatus(2)"
            >
              {{ $t('Nhập hàng') }}
            </b-button>

            <b-button
              v-if="model.status == 2 && model.is_debt == 1"
              v-b-modal.modal-pay-supplier
              variant="warning"
              type="button"
              class="ml-2"
            >
              {{ $t('Thanh toán') }}
            </b-button>

            <b-button
              v-if="model.status == 2 && model.is_debt == 0"
              variant="success"
              type="button"
              disabled
              class="ml-2"
            >
              {{ $t('Đã thanh toán') }}
            </b-button>
          </div>
        </div>
      </b-card>
      <b-row>
        <b-col
          xl="9"
          md="12"
        >
          <b-row class="match-height">
            <!-- supplier -->
            <b-col md="6">
              <b-card :title="$t('Supplier')">
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Supplier"
                  rules="required"
                >
                  <v-select
                    v-model="model.supplier_id"
                    :options="supplierOptions"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="false"
                    class="w-100 mb-1"
                  >
                    <template #list-header>
                      <a
                        class="add-new-client-header d-flex align-items-center my-50"
                        tag="li"
                        href="/supplier/add"
                        target="_blank"
                      >
                        <feather-icon icon="PlusIcon" size="16" />
                        <span class="align-middle ml-50">{{ $t('Add New Supplier') }}</span>
                      </a>
                      <li
                        class="add-new-client-header d-flex align-items-center my-50"
                        @lick="loadSupplier"
                      >
                        <feather-icon icon="RefreshCwIcon" size="16" />
                        <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                <strong>{{ model.supplier.name }}</strong>
                <div class="my-1">
                  {{ model.supplier.address }}
                </div>
                <div class="d-flex justify-content-between">
                  <span v-if="model.supplier.dashboard">Còn nợ: <strong>{{ unitFormatOriginal(model.supplier.dashboard.total_debt || 0) }}</strong></span>
                  <router-link :to="`/supplier/edit/${model.supplier.id}`">
                    Xem chi tiết
                  </router-link>
                </div>
              </b-card>
            </b-col>
            <!-- warehouse -->
            <b-col md="6">
              <b-card :title="$t('Warehouse')">
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Warehouse"
                  rules="required"
                >
                  <v-select
                    v-model="model.warehouse_id"
                    :options="warehouseOptions"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="false"
                    class="mb-1"
                  >
                    <template #list-header>
                      <a
                        class="add-new-client-header d-flex align-items-center my-50"
                        tag="li"
                        href="/supplier/add"
                        target="_blank"
                      >
                        <feather-icon icon="PlusIcon" size="16" />
                        <span class="align-middle ml-50">{{ $t('Add New Warehouse') }}</span>
                      </a>
                      <li
                        class="add-new-client-header d-flex align-items-center my-50"
                        @lick="loadSupplier"
                      >
                        <feather-icon icon="RefreshCwIcon" size="16" />
                        <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                <strong>{{ model.storage.name }}</strong>
                <div class="my-1">
                  {{ model.storage.address }}
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <span>{{ model.storage.phone }}</span>
                  <router-link to="/warehouse-list/edit/1">
                    Xem chi tiết
                  </router-link>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card
                class="mih-100"
                :title="$t('Product')"
              >
                <!-- <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input :placeholder="$t('Search product')" />
                  <b-input-group-append is-text>
                    <span class="cursor-pointer" @click="modalShow = !modalShow">
                      {{ $t('Search') }}
                    </span>
                  </b-input-group-append>
                </b-input-group> -->
                <div class="mt-1">
                  <vue-good-table
                    :columns="columnsDone"
                    :rows="rowsDone"
                    class="custom-good-table-row-head"
                    style-class="vgt-table"
                    :group-options="{
                      enabled: true,
                      headerPosition: 'top'
                    }"
                  >
                    <template
                      slot="table-header-row"
                      slot-scope="props"
                    >
                      <b-avatar
                        v-if="obProd[`id_${props.row.img}`]"
                        :key="props.row.img"
                        :src="obProd[`id_${props.row.img}`].avatar"
                        variant="light-info"
                        rounded
                        class="p-avatar"
                      />
                      <span
                        v-if="obProd[`id_${props.row.label}`]"
                        class="my-fancy-class"
                      >
                        {{ obProd[`id_${props.row.label}`].name }}
                      </span>
                    </template>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <div
                        v-if="props.column.field === 'name'"
                        class="avatar-name mx-auto"
                      >
                        <div class="name-child">
                          {{ props.row.name }}
                        </div>
                        <div class="sku">
                          <strong>SKU:&nbsp;</strong>{{ props.row.sku }}
                        </div>
                      </div>
                      <div
                        v-else-if="props.column.field === 'stock'"
                        class="count-w mx-auto"
                      >
                        {{ props.row.stock }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'quantity'"
                      >
                        <input
                          v-if="warehouses[`id_${props.row.id}`] && model.status == 1"
                          v-model="warehouses[`id_${props.row.id}`].quantity"
                          class="v-custom-input-quantity"
                          type="number"
                          @input="upData(props.row.id)"
                        >
                        <span v-else>{{ warehouses[`id_${props.row.id}`].quantity }}</span>
                        / {{ props.row.quantity_request_goods }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'price'"
                        class="count-w"
                      >
                        {{ unitFormatOriginal(props.row.price) }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'discount'"
                        class="count-w"
                      >
                        {{ unitFormatOriginal(props.row.discount) }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'total'"
                        class="text-end"
                      >
                        {{ unitFormatOriginal(warehouses[`id_${props.row.id}`].total) }}
                      </div>
                    </template>
                  </vue-good-table>
                </div>
              </b-card>
              <b-card
                title="Ghi Chú"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="model.note"
                  :placeholder="$t('Enter note')"
                  rows="3"
                  max-rows="6"
                />
              </b-card>
              <b-card title="Lịch sử">
                <!-- <div class="input-note ml-1">
                  <div class="icon-user-chat">
                    <feather-icon
                      icon="UserIcon"
                      size="40"
                    />
                  </div>
                  <div class="date">
                    16/07/2022
                  </div>
                  <b-input-group class="pb-5">
                    <b-form-input placeholder="Thêm nội dung ghi chú" />
                    <b-input-group-append>
                      <b-button variant="primary">
                        <feather-icon
                          icon="SendIcon"
                          size="20"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div> -->
                <app-timeline>
                  <app-timeline-item
                    v-for="item, index in model.history_edit"
                    :key="index"
                  >
                    <div class="d-flex flex-sm-row flex-wrap justify-content-between mb-1">
                      <h6>
                        {{ $t(mixStatus('receivingGood', item.status).name) }} bởi
                        <router-link
                          v-if="obUsers[`id_${item.user_id}`]"
                          to="/user/edit"
                          class="text-primary"
                        >
                          {{ obUsers[`id_${item.user_id}`].fullname }}
                        </router-link>
                      </h6>
                      <div class="date-cm">
                        {{ item.updated_at }}
                      </div>
                    </div>
                  </app-timeline-item>
                </app-timeline>
                <!-- <hr>
                <b-button
                  variant="danger"
                  type="button"
                  to="add"
                  class="mt-1"
                >
                  {{ $t('Kết thúc') }}
                </b-button> -->
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="12"
          xl="3"
        >
          <div class="position-sticky top">
            <b-card>
              <div class="title-nv">
                Mã phiếu đặt hàng
              </div>
              <div class="id-manh d-flex align-items-center text-primary">
                <div class="name">
                  <router-link
                    :to="`/purchasing-orders/edit/${model.request_goods_id}`"
                    class="text-primary"
                  >
                    {{ `PDH-${model.request_goods_id}` }}
                  </router-link>
                </div>
              </div>
              <!-- <div class="title-nv">
                Nhân viên xử lí
              </div>
              <div class="id-manh d-flex align-items-center text-primary">
                <feather-icon
                  icon="UserIcon"
                  size="14"
                />
                <div class="name">
                  <router-link
                    to="/user/edit"
                    class="text-primary"
                  >
                    Anh Huân
                  </router-link>
                </div>
              </div> -->
              <div class="title-nv">
                Ngày nhận hàng dự kiến
              </div>
              <div class="id-manh d-flex align-items-center text-primary">
                <feather-icon
                  icon="CalendarIcon"
                  size="14"
                />
                <div class="name">
                  {{ dateFormat(model.request_good.expected_date) }}
                </div>
              </div>
            </b-card>
            <b-card title="Chi phí mua hàng">
              <div class="d-flex justify-content-between">
                <span>Tổng số lượng đặt</span>
                <span>{{ sumData.quantity }}</span>
              </div>
              <div class="d-flex justify-content-between my-1">
                <span>Tổng tiền hàng</span>
                <span>{{ unitFormatOriginal(sumData.price) }}</span>
              </div>
              <div class="d-flex justify-content-between my-1">
                <span>VAT</span>
                <span>{{ model.request_good.vat }}</span>
              </div>
              <div class="d-flex justify-content-between my-1">
                <span>Tổng VAT</span>
                <span>{{ unitFormatOriginal(sumData.vat) }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <strong
                  v-b-modal.modal-pay-supplier
                  class="text-primary"
                >Tiền trả NCC</strong>
                <span>{{ unitFormatOriginal(sumData.paid) }}</span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <strong>Còn nợ</strong>
                <span>{{ unitFormatOriginal(sumData.debt) }}</span>
              </div>
              <!-- <div class="text-center mt-2 w-100">
                <b-button
                  variant="primary"
                  type="submit"
                  class="w-100"
                  @click.prevent="validationForm"
                >
                  {{ $t('Stock Input') }}
                </b-button>
              </div> -->
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <!-- modal add selected -->
    <b-modal
      id="modal-list"
      v-model="modalShow"
      size="lg"
      scrollable
      title="Tìm kiếm sản phẩm"
      no-stacking
    >
      <div class="mih-100">
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Button on right" />
          <b-input-group-append is-text>
            <span class="cursor-pointer">
              {{ $t('Search') }}
            </span>
          </b-input-group-append>
        </b-input-group>
        <div class="mt-1">
          <b-row class="bg-gray">
            <b-col cols="9">
              Sản phẩm
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >Số tồn kho</b-col>
          </b-row>
          <div>
            <ul>
              <li
                v-for="(item, index) in items"
                :key="index"
                class="mt-1"
              >
                <div>
                  <label
                    :for="`parent-${item.id}`"
                    class="d-flex align-items-center hover-bg-gray cursor-pointer"
                  >
                    <b-form-checkbox
                      :id="`parent-${item.id}`"
                      v-model="item.selected"
                      :indeterminate="item.indeterminate"
                      @change="handleSelectAll(item)"
                    />
                    <div class="d-flex align-items-center pl-1">
                      <b-avatar
                        square
                        variant="light-dark"
                        :src="item.avatar"
                      />
                      <span class="pl-1">{{ item.name }}</span>
                    </div>
                  </label>
                </div>
                <ul>
                  <li
                    v-for="(val, idx) in item.attribute"
                    :key="idx"
                    class="mt-1"
                  >
                    <label
                      :for="`${val.id}`"
                      class="d-flex justify-content-between hover-bg-gray cursor-pointer"
                    >
                      <div class="d-flex pl-4">
                        <b-form-checkbox
                          :id="`${val.id}`"
                          v-model="val.selected"
                          @change="handleSelectItem(val)"
                        />
                        <div class="pl-1">
                          <span class="d-block">{{ val.name }}</span>
                          <span class="d-block"><strong>SKU:&nbsp;</strong>{{ val.sku }}</span>
                          <span class="d-block"><strong>Barcode:&nbsp;</strong>{{ val.barcode }}</span>
                        </div>
                      </div>
                      <div class="pr-1">{{ val.stock }} tồn kho</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <p
            class="float-left mb-0 "
            :class="selectedArr.length ? 'text-primary cursor-pointer' : ''"
            @click="modalShowItem = !modalShowItem"
          >
            {{ selectedArr.length }} sản phẩm đã chọn
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="show = false"
          >
            Hoàn tất chọn
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="float-right mr-2"
            @click="modalShow = false"
          >
            Huỷ
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- modal show item be selected -->
    <b-modal
      id="modal-show-item"
      v-model="modalShowItem"
      size="lg"
      scrollable
      title="Sản phẩm đã chọn"
      no-stacking
    >
      <div class="mih-100">
        <div>
          <b-row class="bg-gray">
            <b-col cols="9">
              Sản phẩm
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >Số tồn kho</b-col>
          </b-row>
          <div>
            <ul>
              <li
                v-for="(item, index) in items"
                :key="index"
                class="mt-1"
              >
                <div>
                  <label
                    :for="`parent-${item.id}`"
                    class="d-flex align-items-center hover-bg-gray"
                  >
                    <!-- <b-form-checkbox :id="`parent-${item.id}`" v-model="item.selected" :indeterminate="item.indeterminate" @change="handleSelectAll(item)"/> -->
                    <div class="d-flex align-items-center pl-1">
                      <b-avatar
                        square
                        variant="light-dark"
                        :src="item.avatar"
                      />
                      <span class="pl-1">{{ item.name }}</span>
                    </div>
                    <div class="pl-2">
                      <feather-icon
                        class="cursor-pointer"
                        size="18"
                        icon="XIcon"
                      />
                    </div>
                  </label>
                </div>
                <ul>
                  <li
                    v-for="(val, idx) in item.attribute"
                    :key="idx"
                    class="mt-1"
                  >
                    <label
                      :for="`${val.id}`"
                      class="d-flex justify-content-between hover-bg-gray"
                    >
                      <div class="d-flex pl-4">
                        <!-- <b-form-checkbox :id="`${val.id}`" v-model="val.selected" @change="handleSelectItem(val)" /> -->
                        <div class="pl-1">
                          <span class="d-block">{{ val.name }}</span>
                          <span class="d-block"><strong>SKU:&nbsp;</strong>{{ val.sku }}</span>
                          <span class="d-block"><strong>Barcode:&nbsp;</strong>{{ val.barcode }}</span>
                        </div>
                      </div>
                      <div class="pr-1">
                        <feather-icon
                          class="cursor-pointer"
                          size="18"
                          icon="XIcon"
                        />
                      </div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <p
            class="float-left mb-0 "
          >
            {{ selectedArr.length }} sản phẩm đã chọn
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="show = false"
          >
            Hoàn tất chọn
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="float-right mr-2"
            @click="modalShow = false"
          >
            Huỷ
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- pay supplier -->
    <b-modal
      id="modal-pay-supplier"
      ref="modal-pay-supplier"
      title="Thanh toán cho nhà cung cấp"
      cancel-variant="outline-secondary"
      size="lg"
      no-close-on-backdrop
    >
      <b-card-text>
        <div class="title-price">
          <h5>Tông tiền cần trả nhà cung cấp</h5>
          <div class="price-modal text-primary">
            {{ unitFormatOriginal(sumData.debt) }}
          </div>
        </div>
        <div class="list-payment mt-1">
          <div class="d-flex justify-content-between mb-1">
            <b-form-checkbox
              v-model="modelPayment.is_money"
              name="is_money"
            >
              Tiền mặt
            </b-form-checkbox>
            <div
              v-if="modelPayment.is_money"
              class="v-input-mark"
            >
              <input
                v-model.number="modelPayment.money"
                type="number"
                class="v-true form-control"
                @input="mixFixVal(modelPayment, 'money')"
              >
              <input
                :value="unitFormatOriginal(modelPayment.money)"
                class="v-false form-control"
                readonly
                tab-index="-1"
              >
            </div>
            <!-- <b-form-input  v-model.number="modelPayment.money" class="w-175px" /> -->
          </div>
          <div class="mb-1">
            <b-form-checkbox
              v-model="modelPayment.is_bank"
              name="is_bank"
            >
              Chuyển khoản
            </b-form-checkbox>
            <div
              v-if="!modelbank.show && modelPayment.is_bank"
              class="group-pay-bank"
            >
              <div
                v-for="(item, index) in modelPayment.list"
                :key="index"
                class="d-flex justify-content-between align-items-center ml-2 mt-1"
              >
                <v-select
                  v-model="modelPayment.list[index].info"
                  :options="bankOptions"
                  label="name"
                  input-id="invoice-data-client"
                  :clearable="false"
                  class="w-50"
                  placeholder="Chọn tài khoản"
                >
                  <template #list-header>
                    <div
                      class="add-new-client-header d-flex align-items-center my-50"
                      tag="li"
                      @click="opBank(index)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="16"
                      />
                      <span class="align-middle ml-50">{{ $t('Thêm tài khoản') }}</span>
                    </div>
                  </template>
                </v-select>
                <div class="d-flex justify-content-between align-items-center w-50">
                  <!-- <b-form-input v-model.number="modelPayment.list[index].money" class="mx-1" /> -->
                  <div
                    class="v-input-mark mx-1"
                  >
                    <input
                      v-model.number="modelPayment.list[index].money"
                      type="number"
                      class="v-true form-control"
                      @input="mixFixVal(modelPayment.list[index], 'money')"
                    >
                    <input
                      :value="unitFormatOriginal(modelPayment.list[index].money)"
                      class="v-false form-control"
                      readonly
                      tab-index="-1"
                    >
                  </div>
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="handleDelete(index)"
                  />
                </div>
              </div>
              <div class="text-primary mt-2 ml-2">
                <span
                  class="cursor-pointer"
                  @click="handleAddPay"
                >+ Thêm tài khoản</span>
              </div>
            </div>
          </div>
          <div
            v-if="modelbank.show && modelPayment.is_bank"
            class="form-add-bank"
          >
            <b-row>
              <b-col md="6">
                <b-form-input
                  v-model="modelbank.name"
                  :placeholder="$t('Chủ tài khoản')"
                  class="mb-1"
                />
              </b-col>
              <b-col md="6">
                <b-form-input
                  v-model="modelbank.stk"
                  :placeholder="$t('Số tài khoản')"
                  class="mb-1"
                />
              </b-col>
              <b-col md="6">
                <b-form-input
                  v-model="modelbank.name_bank"
                  :placeholder="$t('Tên ngân hàng')"
                  class="mb-1"
                />
              </b-col>
              <b-col md="6">
                <b-form-input
                  v-model="modelbank.chinhanh"
                  :placeholder="$t('Chi nhánh')"
                  class="mb-1"
                />
              </b-col>
            </b-row>
            <b-button
              variant="outline-secondary"
              size="sm"
              class="mr-1"
              @click="modelbank.show = false"
            >
              {{ $t('Quay lại') }}
            </b-button>
            <b-button
              variant="secondary"
              size="sm"
              @click="addBank()"
            >
              {{ $t('Thêm') }}
            </b-button>
          </div>
        </div>
      </b-card-text>

      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="success"
          @click="okPayment()"
        >
          Xác nhận
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Đóng
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
// eslint-disable vue/max-attributes-per-line
import { ValidationObserver } from 'vee-validate'
// ValidationProvider,
import {
  BRow,
  BCol,
  // BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  // BFormCheckbox,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  // BCardHeader,
  BAvatar,
  // BPagination,
  // BFormSelect,
  BFormCheckbox,
  BBadge,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { VueGoodTable } from 'vue-good-table'
// import Treeselect from '@riophae/vue-treeselect'
// import vrcode from '@ispa.io/vrcode'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    // BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    // Form Validation
    // ValidationProvider,
    ValidationObserver,
    // BFormCheckbox,
    BCard,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupPrepend,
    // BCardHeader,
    BAvatar,
    VueGoodTable,
    // BPagination,
    // BFormSelect,
    BFormCheckbox,
    BBadge,
    vSelect,
    // Treeselect,
    // vrcode,
    AppTimelineItem,
    AppTimeline,
    BCardText,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      obProd: {},
      avatarText,
      required,
      supplierOptions: [
        {
          id: 1,
          name: 'Nhà Cung cấp 01',
        },
      ],
      warehouseOptions: [],
      model: {
        name: null,
        qrcode: null,
        code: null,
        place: null,
        tag: null,
        quantity: null,
        position: null,
        is_active: true,
        supplier: {},
        storage: {},
        request_good: {},
      },
      supplierDebt: 0,
      modelbank: {
        show: false,
        name: null,
        name_bank: null,
        chinhanh: null,
        stk: null,
        index: null,
      },
      modelPayment: {
        money: null,
        is_money: false,
        is_bank: false,
        list: [],
      },
      sumData: {
        quantity: 0,
        price: 0,
        vat: 0,
        paid: 0,
        paid_done: 0,
        debt: 0,
      },
      pageLength: 10,
      // setting col row list be selected
      columns: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
        },
        {
          label: 'Tồn kho',
          field: 'inventory',
          tdClass: 'text-center',
        },
      ],
      rows: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm phú quốc', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm Nam Ngư', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
      ],
      // setting col row list select
      rowsDone: [],
      warehouses: {},
      columnsDone: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          label: 'Tồn kho',
          field: 'stock',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'SL đặt',
          field: 'quantity',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Đơn giá',
          field: 'price',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Giá giảm',
          field: 'discount',
          tdClass: 'text-center miw-150',
          sortable: false,
        },
        {
          label: 'Thành tiền',
          field: 'total',
          tdClass: 'text-right miw-150',
          thClass: 'text-right',
          sortable: false,
        },
        {
          label: '',
          field: 'close',
          tdClass: 'text-end',
          sortable: false,
        },
      ],
      // setting col row list selected
      rowsAdded: [
        {
          mode: 'span', // span means this header will span all columns
          label: 'Rau cải', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm phú quốc', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
        {
          mode: 'span', // span means this header will span all columns
          label: 'Nước mắm Nam Ngư', // this is the label that'll be used for the header
          img: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          html: false, // if this is true, label will be rendered as html
          children: [
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
            {
              name: 'Màu hồng/S/Lụa',
              sku: '012345489',
              inventory: 600,
              actualstock: 98,
              count: 10,
              value: 0,
              close: '',
            },
          ],
        },
      ],
      columnsAdded: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
          sortable: false,
        },
        {
          label: 'Tool',
          field: 'close',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      modalShow: false,
      modalShowItem: false,
      items: [
        {
          id: 1,
          name: 'Áo thun bao lỗ cực mát',
          avatar:
            'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 1,
              id: 11,
              name: 'Xanh/ S/ Thun',
              stock: 5,
              sku: 'A0038687',
              barcode: 'A0038687',
              selected: false,
            },
            {
              parent_id: 1,
              id: 12,
              name: 'Đen/ S/ Thun',
              stock: 5,
              sku: 'A00386877',
              barcode: 'A00386877',
              selected: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Áo Khoác cực mát',
          avatar:
            'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 2,
              id: 21,
              name: 'Đen/ XL/ Kaki',
              stock: 1,
              sku: 'A00386878',
              barcode: 'A00386878',
              selected: false,
            },
          ],
        },
      ],
      pSelected: [],
      cSelected: [],
      // list tạm thời đã chọn
      selectedArr: [],
      text: '',
      modelPay: [],
      pay: [],
      bankOptions: [],
      idUsers: [],
      obUsers: {},
    }
  },
  watch: {
    cSelected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.flavours.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  async created() {
    this.modelbankJson = JSON.stringify(this.modelbank)
    this.modelPaymentJson = JSON.stringify(this.modelPayment)

    await this.loadDetail()
    this.getBank()
    this.loadSupplierDetail()
  },
  methods: {
    async getUser() {
      const params = {
        list_id: JSON.stringify(this.idUsers),
      }
      const res = await this.mixGet('/v2/users', params)
      if (res.status) {
        res.data.items.map(val => {
          const temp = {}
          temp[`id_${val.id}`] = val
          this.obUsers = { ...this.obProd, ...temp }
          return true
        })
      }
    },
    async okPayment() {
      const payList = []
      if (this.modelPayment.is_money) {
        payList.push({
          money: this.modelPayment.money || 0,
          type: 1,
        })
      }
      if (this.modelPayment.is_bank) {
        this.modelPayment.list.map(val => {
          payList.push({
            money: val.money || 0,
            type: 2,
            info: JSON.stringify(val.info),
          })
          return true
        })
      }
      const params = {
        site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
        receiving_goods_id: this.$route.params.id,
        lists: JSON.stringify(payList),
      }
      const res = await this.mixPost('/history_payment_receiving_goods', params)
      if (res.status) {
        this.modelPayment = JSON.parse(this.modelPaymentJson)
        this.loadDetail()
        this.$refs['modal-pay-supplier'].hide()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Congratulation !',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Payment success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(this.showError(res.error, ',')),
          },
        })
      }
    },
    async getBank(index = null) {
      const res = await this.mixGet('/setting/bank_receiving_good', {})
      if (res.status) {
        const temp = JSON.parse(res.data.value)
        this.bankOptions = temp
        if (index !== null) {
          this.modelPayment.list[index].info = temp[0] ? temp[0] : null
        }
      }
    },
    opBank(index) {
      this.modelbank.show = true
      this.modelbank.index = index
    },
    async addBank() {
      const bankList = JSON.parse(JSON.stringify(this.bankOptions))
      const { index } = this.modelbank
      bankList.unshift({
        name: this.modelbank.name,
        name_bank: this.modelbank.name_bank,
        stk: this.modelbank.stk,
        chinhanh: this.modelbank.chinhanh,
      })
      const params = {
        key: 'bank_receiving_good',
        value: JSON.stringify(bankList),
      }
      const res = await this.mixPut('/setting/bank_receiving_good', params)
      if (res.status) {
        this.modelbank.show = false
        this.modelbank = JSON.parse(this.modelbankJson)
        this.getBank(index)
      }
      if (res.error && res.error.setting && res.error.setting === 'Setting not exist') {
        const res2 = await this.mixPost('/settings', params)
        if (res2.status) {
          this.modelbank.show = false
          this.modelbank = JSON.parse(this.modelbankJson)
          this.getBank(index)
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
    handleSelectPay(index) {
      this.pay[index].id = this.pay[index].selected.id
      this.pay[index].name = this.pay[index].selected.name
    },
    handleAddPay() {
      this.modelPayment.list.push(
        {
          money: null,
          type: 2,
          info: null,
        },
      )
    },
    handleDelete(index) {
      this.modelPayment.list.splice(index, 1)
    },
    handleSelectItem(val) {
      // eslint-disable-next-line
      // find index of attribute in arr
      const parent = this.items.findIndex(x => x.id === val.parent_id)
      let d = 0
      // eslint-disable-next-line array-callback-return
      this.items[parent].attribute.map(x => {
        if (x.selected) {
          // eslint-disable-next-line no-plusplus
          d++
        }
      })
      if (d > 0) this.items[parent].indeterminate = true
      if (d === 0) {
        this.items[parent].selected = false
        this.items[parent].indeterminate = false
      }
      if (d === this.items[parent].attribute.length) {
        this.items[parent].selected = true
        this.items[parent].indeterminate = false
      }
    },
    handleSelectAll(val) {
      const index = this.items.find(x => x.id === val.id)
      // eslint-disable-next-line array-callback-return
      index.attribute.map(x => {
        // eslint-disable-next-line no-param-reassign
        x.selected = val.selected
      })
      this.saveTempArr()
    },
    async loadSupplierDetail() {
      const year = new Date().getFullYear()
      const params = {
        dashboard: true,
        date_from: `${year}-01-01`,
        date_to: `${year}-12-31`,
      }
      const res = await this.mixGet(`/supplier/${this.model.supplier.id}`, params)
      if (res.status) {
        this.supplierDebt = res.data.dashboard.total_debt
      }
    },
    saveTempArr(item, option = {}) {
      // eslint-disable-next-line array-callback-return
      if (option.all) {
        this.selectedArr.map(x => x.id.include(item.id))
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              address: this.model.address,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              name: this.model.name,
              phone: this.model.phone,
              address: this.model.address,
              translation: JSON.stringify(translation),
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/supplier`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create success',
                  },
                })
                this.$router.push('/warehouse-list/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },

    async loadDetail() {
      const end = true
      const res = await this.mixGet(`/receiving_goods/${this.$route.params.id}`)
      this.warehouses = {}
      if (res.status) {
        const model = res.data
        model.storage = model.request_good.storage
        model.history_edit = JSON.parse(model.history_edit || '[]')
        model.history_edit.unshift({
          user_id: model.user_id,
          status: 1,
          updated_at: model.created_at,
        })
        if (model.status === 2) {
          const findDate = model.history_edit.find(x => x.status === 2).updated_at
          model.stock_date = findDate
        }
        this.model = { ...this.model, ...model }
        const temp = []
        const ids = []
        const realRes = []
        const tempRequestGoodsProduct = {}

        res.data.request_good.request_goods_product.map(val => {
          tempRequestGoodsProduct[`key_${val.warehouse_id}`] = val
          return true
        })

        if (!this.idUsers.includes(model.user_id)) {
          this.idUsers.push(model.user_id)
        }
        model.history_edit.map(val => {
          if (!this.idUsers.includes(val.user_id)) {
            this.idUsers.push(val.user_id)
          }
          return true
        })

        this.getUser()

        const sumData = {
          quantity: 0,
          price: 0,
          vat: 0,
          paid: 0,
          paid_done: model.money_paid,
          debt: 0,
        }

        res.data.receiving_goods_product.map(val => {
          if (ids.includes(val.warehouse.product_id) === false) {
            ids.push(val.warehouse.product_id)
          }
          const nameAttribute = JSON.parse(val.warehouse.name_attribute)
          const str = []
          nameAttribute.map(val2 => {
            str.push(val2.value)
            return true
          })
          const item = {
            created_at: val.created_at,
            discount: tempRequestGoodsProduct[`key_${val.warehouse_id}`].discount_price,
            id: val.id,
            price: tempRequestGoodsProduct[`key_${val.warehouse_id}`].price,
            quantity: val.quantity,
            quantity_request_goods: tempRequestGoodsProduct[`key_${val.warehouse_id}`].quantity,
            request_goods_id: val.request_goods_id,
            site_id: val.site_id,
            updated_at: val.updated_at,
            user_id_process: val.user_id_process,
            warehouse_id: val.warehouse_id,
            product_id: val.warehouse.product_id,
            name_attribute: nameAttribute,
            name: str.join('-'),
            sku: val.warehouse.sku,
            stock: val.warehouse.storages_warehouse[0] ? val.warehouse.storages_warehouse[0].quantity : 0,
            total: val.quantity * (tempRequestGoodsProduct[`key_${val.warehouse_id}`].price - tempRequestGoodsProduct[`key_${val.warehouse_id}`].discount_price),
          }
          const itemWh = {}
          sumData.quantity += item.quantity
          sumData.price += item.total

          itemWh[`id_${val.id}`] = item
          this.warehouses = { ...this.warehouses, ...itemWh }
          temp.push(item)
          return true
        })

        sumData.vat = (sumData.price / 100) * model.request_good.vat
        sumData.paid = sumData.price + sumData.vat
        sumData.debt = sumData.paid - sumData.paid_done
        this.sumData = sumData

        ids.map(val => {
          // eslint-disable-next-line camelcase
          const findData = temp.filter(({ product_id }) => product_id === val)
          realRes.push(
            {
              mode: 'span', // span means this header will span all columns
              label: val, // this is the label that'll be used for the header
              img: val,
              html: false, // if this is true, label will be rendered as html
              product_id: val,
              children: findData,
            },
          )
          return true
        })
        this.rowsDone = realRes
        this.getProducts(ids)
        this.getSup(res.data.request_good.supplier_id)
      }
      return end
    },

    async getProducts(ids = []) {
      this.obProd = {}
      const params = {
        '&sort[]': 'created_at,desc',
        list_product_id: JSON.stringify(ids),
      }
      const res = await this.mixGet('/products', params)
      if (res.status) {
        res.data.items.map(val => {
          const temp = {}
          temp[`id_${val.id_number}`] = val
          this.obProd = {
            ...this.obProd,
            ...temp,
          }
          return true
        })
      }
    },

    async getSup(id) {
      const year = new Date().getFullYear()
      const dateFrom = `${year}-01-01`
      const dateTo = `${year}-12-01`
      const res = await this.mixGet(`/supplier/${id}`, { dashboard: true, date_from: dateFrom, date_to: dateTo })
      if (res.status) {
        this.model.supplier = res.data
      }
    },

    // eslint-disable-next-line consistent-return
    async updateStatus(status) {
      const warehouses = []
      Object.keys(this.warehouses).map(idKey => {
        const item = this.warehouses[idKey]
        if (item.quantity > 0) {
          warehouses.push({
            id: item.id,
            warehouse_id: item.warehouse_id,
            quantity: item.quantity,
            type: 2,
          })
        }
        return true
      })
      if (warehouses.length === 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: 'Số lượng nhâp kho phải lớn hơn 0',
          },
        })
        return false
      }
      const params = {
        status,
        warehouses: JSON.stringify(warehouses),
        note: this.model.note || '',
      }
      const res = await this.mixPut(`/receiving_goods/${this.$route.params.id}`, params)
      if (res.status) {
        this.loadDetail()
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(this.showError(res.error, ',')),
          },
        })
      }
    },

    upData(id) {
      const item = this.warehouses[`id_${id}`]
      const sumData = {
        quantity: 0,
        price: 0,
        vat: 0,
        paid: 0,
        paid_done: 0,
        debt: 0,
      }

      item.quantity = Number(item.quantity)
      if (item.quantity < 0) {
        item.quantity = 0
      }
      if (item.quantity > item.quantity_request_goods) {
        item.quantity = item.quantity_request_goods
      }
      item.total = Number(item.quantity) * (item.price - item.discount)

      Object.keys(this.warehouses).map(idKey => {
        sumData.quantity += this.warehouses[idKey].quantity
        sumData.price += this.warehouses[idKey].total
        return true
      })

      sumData.vat = (sumData.price / 100) * this.model.request_good.vat
      sumData.paid = sumData.price + sumData.vat
      sumData.debt = sumData.paid - sumData.paid_done
      this.sumData = sumData
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
.add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;
    cursor: pointer;

    &:hover {
        background-color: rgba($success, 0.12);
    }
}
.custom-good-table-row-head {
    .custom-select {
        width: 100%;
    }
    .b-avatar {
        margin-right: 8px;
    }
    table.vgt-table {
        font-size: 14px !important;
    }
    thead th {
        vertical-align: middle;
        text-align: center;
        // padding-right: 0.75em;
    }
    tbody th.vgt-checkbox-col {
        padding: 0 0.75em 0 2.75em;
        border-right: none;
    }
    td {
        vertical-align: middle !important;
    }
    .p-avatar {
        margin-left: 16px;
    }
    .avatar-name {
        min-width: 200px !important;
        padding-left: 4.25em;

        .name-child {
            color: #333;
            font-weight: 500;
        }
    }
    .count-w {
        max-width: 100px;
    }
    .miw-150 {
        min-width: 150px;
    }
    .vgt-table th.vgt-row-header {
        // border-top: 3px solid #ebe9f1;
        border-bottom: none;
        font-size: 14px;
        thead th {
            vertical-align: middle;
            text-align: center;
            padding-right: 0.75em;
            padding-left: 0;
            white-space: nowrap;
        }
        td {
            vertical-align: middle;
        }

        .custom-th-class {
            text-align: end;
        }
    }
}
#modal-show-item {
    table.vgt-table {
        thead {
            display: none;
        }
        tbody td.text-left {
            padding-left: 5.25em;
            border-bottom: none;
        }
        tbody td.text-center {
            border-bottom: none;
        }
    }
}
.code-block {
    &:not(:last-child) {
        margin-right: 16px;
        padding-right: 16px;
        border-right: 1px solid #f2f2f2;
        .code {
            font-size: 18px;
            color: #000;
            font-weight: 500;
        }
    }
    .code {
        margin-top: 8px;
    }
}
.prin-block {
    font-size: 14px;
    line-height: 18px;
    background: #e9ecef !important;
    border-radius: 4px !important;
    display: inline-block;
    border: none;
    height: 38px;
    color: #000 !important;

    &:hover {
        opacity: 0.8;
    }

    .name {
        margin-left: 8px;
    }
}
</style>
<style lang="scss" scoped>
.v-custom-input-quantity{
  display: inline-block;
  width: 70px;
}
ul {
    padding: 0;
    margin-top: 1rem;
}
ul li {
    list-style: none;
}
.bg-gray {
    background: #f6f6f6;
    padding: 12px 0;
}
.hover-bg-gray {
    padding: 0.5rem 0;
}
.hover-bg-gray:hover {
    padding: 0.5rem 0;
    background: #f6f6f6;
}
label {
    font-size: 14px;
}
.mih-100 {
    min-height: 350px;
}
.w-175px {
    width: 175px;
}
</style>
<style lang="scss" scoped>
.input-note {
    display: flex;
    align-items: center;
    position: relative;

    .date {
        position: absolute;
        bottom: 2rem;
        left: 2.5rem;
        font-weight: 700;
    }

    .btn {
        padding: 0 1.5rem;
        svg {
            transform: rotate(45deg);
            color: #fff;
        }
    }

    .icon-user-chat {
        position: absolute;
        top: 0;
        left: -18px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        color: #fff;
        background-color: rgb(60, 148, 209);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 5px solid rgb(60, 148, 209);
        z-index: 1;
    }
    .input-group {
        padding-left: 2.5rem;
        border-left: 1px solid #ebe9f1;
    }
}
.code-block {
    &:not(:last-child) {
        margin-right: 16px;
        padding-right: 16px;
        border-right: 1px solid #f2f2f2;
        .code {
            font-size: 18px;
            color: #000;
            font-weight: 500;
        }
    }
    .code {
        margin-top: 8px;
    }
}
.prin-block {
    font-size: 14px;
    line-height: 18px;
    background: #e9ecef !important;
    border-radius: 4px !important;
    display: inline-block;
    border: none;
    height: 38px;
    color: #000 !important;

    &:hover {
        opacity: 0.8;
    }

    .name {
        margin-left: 8px;
    }
}
ul {
    padding: 0;
    margin-top: 1rem;
}
ul li {
    list-style: none;
}
.bg-gray {
    background: #f6f6f6;
    padding: 12px 0;
}
.hover-bg-gray {
    padding: 0.5rem 0;
}
.hover-bg-gray:hover {
    padding: 0.5rem 0;
    background: #f6f6f6;
}
label {
    font-size: 14px;
}
.mih-100 {
    min-height: 350px;
}
.bold {
    font-weight: bold;
}
.title-nv {
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.id-manh {
    margin-bottom: 1rem;
    font-weight: 600;
    .name {
        margin-left: 4px;
    }
}
.title-hc {
    font-weight: 600;
    font-size: 18px;
    padding-top: 8px;
    border-top: 1px solid #d8d6de;
    margin-bottom: 8px;
}
.title-price {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    padding-bottom: 4px;
    border-bottom: 1px solid #d8d6de;
    margin-bottom: 8px;
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
